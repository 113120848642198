import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Button from 'react-bootstrap/Button';
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Docs1 from '../assets/docs/RG_GLOBAL-BENS-BRAND-RISK-MANAGEMENT-DEV-RG 1.pdf'
import Docs2 from '../assets/docs/INVENTORYPERFORMANCE-EU-DEV-RG 1.pdf'
import Docs3 from '../assets/docs/GLOBAL-XSEG-LOSS-CHASERS-DEV-RG 1.pdf'
import Docs4 from '../assets/docs/EFMOS-ACTION-DASHBOARD-DEV-RG 1.pdf'
import Docs5 from '../assets/docs/GLOBAL-XSEG-ZEROTOUCHORDERS-DEV 1.pdf'
import Docs6 from '../assets/docs/GLOBAL-XSEG-LOGISTICS-COST-TOOLKIT-DEV-RG 1.pdf'
import Docs7 from '../assets/docs/GLOBAL-XSEG-SUPPLYCHAIN-RAWSANDPACKS-DEV-RG 1.pdf';
import Docs8 from '../assets/docs/EU-XSEG-SPM-SUPPLY-CHAIN-DEV-RG 1.pdf'


const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(2, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const content = [
    {
        id: 1,
        heading: 'GLOBAL-BENS-BRAND-RISK-MANAGEMENT-DEV-RG',
        pdf: Docs1
    },
    {
        id: 2,
        heading: 'INVENTORYPERFORMANCE-EU-DEV-RG',
        pdf: Docs2
    },
    {
        id: 3,
        heading: 'GLOBAL-XSEG-LOSS-CHASERS-DEV-RG',
        pdf: Docs3
    },
    {
        id: 4,
        heading: 'EFMOS-ACTION-DASHBOARD-DEV-RG',
        pdf: Docs4
    },
    {
        id: 5,
        heading: 'GLOBAL-XSEG-ZEROTOUCHORDERS-DEV',
        pdf: Docs5
    },
    {
        id: 6,
        heading: 'GLOBAL-XSEG-LOGISTICS-COST-TOOLKIT-DEV-RG',
        pdf: Docs6
    },
    {
        id: 7,
        heading: 'GLOBAL-XSEG-SUPPLYCHAIN-RAWSANDPACKS-DEV-RG',
        pdf: Docs7
    },
    {
        id: 7,
        heading: 'EU-XSEG-SPM-SUPPLY-CHAIN-DEV-RG',
        pdf: Docs8
    }
]

function ArchitectureEvaluation() {
    const[data, setData] = useState(content);
    const[openModal, setOpenModal] = useState('');

    const handleSearch = (e) => {
        const { value } = e.target;
        const filteredData = content.filter((ele) => ele.heading.toLowerCase().includes(value.toLowerCase()));
        setData(filteredData);
    }

  return (
    <div className='rg-evaluaion'>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden",overflowY:"auto" }}>
            <MainLayout />

            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                <DrawerHeader />
                <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

                <div id="mainHome">
                <div style={{padding:"2rem 1rem 1rem", marginBottom: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                <div className='main-heading'>
                    Architecture Evaluation
                </div>
                <div className='line' />
                </div>

                <div className="search-box">
                    <input type="text" className="search" placeholder="Search for Content name..." onChange={(e) => handleSearch(e)}/>
                </div>
                </div>

                <div className='heading-container'>
                    {data.map((item) =>
                        <div className='heading-box' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div className='text'>{item.heading}</div>
                            <Button variant="primary" onClick={() => setOpenModal(item.pdf)}>view</Button>
                        </div>
                    )}
                </div>

                <Modal
                    open={openModal}
                    onClose={() => setOpenModal('')}
                >
                <div className="modal-container">
                    <embed type="application/pdf" src={openModal + "#toolbar=0"} height={620} width={800}/>
                </div>
                </Modal>

                </div>

            </Box>
        </Box>
    </div>
  )
}

export default ArchitectureEvaluation;
