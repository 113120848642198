import React, { useState } from "react";
// import { FaRegChartBar } from "react-icons/fa";
import { ReactComponent as PowerBiIcon } from "../assets/img/icons8-power-bi-2021.svg";
import { FaLink, FaRegChartBar, FaTools,FaOutdent,FaThList,FaThLarge } from "react-icons/fa";
import DetailsPage from "../reuseable/DetailsPage";

// const color = ['#0000a0', '#1a0127', '#14b965', '#9baf83', '#32a939', '#253978', '#57f22c', '#87115d', '#db05e9', '#144e08', '#21ccaf', '#7281f2', '#d567d4', '#24d1b0', '#fa1411', '#ad002e', '#379fba', '#af90e1', '#4b503f', '#ab4495', '#24695f', '#ee1537', '#edcac5', '#dcc082', '#b11724', '#62436d', '#c4d461', '#055fbf', '#272981', '#d599ed', '#6e1784', '#3c52d4', '#dd4d94', '#c85623', '#bfb87a', '#62fb47', '#5af5e8', '#b82994', '#8e4d70', '#9ed736', '#c9f824', '#83e44f', '#c43716', '#4dad6b', '#d05427', '#d15853', '#dbe218', '#b7f0e0', '#7c56a3', '#fae8d1', '#58107f', '#81a065', '#7d1268', '#a13c68', '#c92613', '#bb7b82', '#e2cd4c', '#6f1e39', '#8440f4', '#09204a', '#e6f92d', '#989a3a', '#e92998', '#efaf3a']
const color = ["#bb1ccc"];

function Cards({
  data = [],
  category = "",
  setOpenModal = () => {},
  setReportName = () => {},
}) {
  const filteredData =
    category !== "All"
      ? data.filter((item) => item.category.includes(category))
      : data;

  function generateClassName(category) {
    const words = category.split(" ");
    let className = "" + words[0].toUpperCase(); // First letter of the first word

    if (words.length > 1) {
      const secondWord = words[1];
      if (secondWord && secondWord.length > 0) {
        className += "-" + secondWord.toUpperCase();
      }
    }

    return className.toLowerCase();
  }


  return (
    <div>
      <section>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between",paddingRight:"20px" }}>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "15px",
                fontSize: "18px",
                paddingBottom: "18px",
              }}
            >
              Number of Apps -{" "}
              <span style={{ fontWeight: "700" }}>{filteredData.length}</span>
            </div>

           
          </div>

          <div class="text-center m-t-120">
            <div className="items-main" style={{ cursor: "text" }}>
              {filteredData.map((item, index) => {
                return (
                  <div key={index}>
                    <div class="features-item">
                      <div
                        style={{ borderColor: `${color[index]}` }}
                        class="features-icon"
                      >
                        <FaRegChartBar
                          style={{ color: "#3d3d3d", fontSize: "28px" }}
                        />
                      </div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${item.Toollink}`}
                        style={{
                          position: "absolute",
                          top: "5%",
                          right: "20px",
                          padding: "5px",
                          borderRadius: "6px",
                          color: "#0000a0",
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <div title="view report">
                          {/* <img className='imgClassNew' src={iconsData} width={30} alt='img'/> */}
                          {item.ReportType === "Power BI" ? (
                            <div className="powerBitag">
                              <PowerBiIcon width={10} height={10} />
                              PowerBI
                            </div>
                          ) : (
                            <>
                              <div className="ApplicationTag">
                                <FaLink style={{ fontSize: "10px" }} />
                                Applications
                              </div>
                            </>
                          )}
                        </div>
                        {/* <div style={{fontSize: '13px', fontWeight: '500'}}>PowerBI</div> */}
                      </a>
                      <div class="features-info">
                        <div style={{display:"grid",paddingLeft:"10px",gap:"5px"}}>
                          <sapn title={item.Header} class="Headertitle">
                            {item.Header} - {item.SubHeader}
                          </sapn>

                          {/* <span title={item.SubHeader} class="subtitle">
                            {item.SubHeader}
                          </span> */}
                        </div>

                        {item.type.length !== 0 && (
                          <div
                            style={{
                              display: "grid",
                              textAlign: "center",
                              wordBreak: "break-word",
                              padding: "10px",
                            }}
                            className="TagCls"
                          >
                            <div className="List_items">
                              {item.type.slice(0, 4).map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={`item-type  item-type-${generateClassName(
                                      item
                                    )}`}
                                  >
                                    <span
                                      title={item}
                                      className="item-type-span"
                                    >
                                      {item}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}

                        {item.description !== null ? (
                          <div
                            style={{
                              display: "grid",
                              textAlign: "center",
                              wordBreak: "break-word",
                              padding: "10px",
                            }}
                            className="DesCls"
                            title={item.description}
                          >
                            {item.description}
                          </div>
                        ) : (
                          <div className="DesCls"></div>
                        )}
                      </div>

                      <div
                        style={{
                          display: "grid",
                          justifyContent: "flex-start",
                          paddingLeft: "10px",
                        }}
                      >
                        <>
                          <div style={{ color: "#fff", fontWeight: "600" }}>
                            <DetailsPage
                              name={item.Header}
                              desc={item.description}
                              type={item.type}
                              reportType={item.ReportType}
                              Link={item.Toollink}
                            />
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

         


        
        </div>
      </section>
    </div>
  );
}

export default Cards;
