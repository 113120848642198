import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import dataJson from '../../data/report-store.json';
import './Datatable.scss';

const Datatable = () => {
    const [data] = useState(dataJson);
    const [searchTerm, setSearchTerm] = useState('');
    const [keyFeatureFilter, setKeyFeatureFilter] = useState('');
    const [appNameFilter, setAppNameFilter] = useState('');
    const [leversOfBenefitFilter, setLeversOfBenefitFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    // Gather unique values for Key Feature, App Name, and Levers of Benefit
    const uniqueKeyFeatures = [...new Set(data.map(item => item["Key Feature"]))];
    const uniqueAppNames = [...new Set(data.map(item => item["App Name"]))];
    const uniqueLeversOfBenefit = [...new Set(data.map(item => item["Levers of Benefit"]))];

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(0);  // Reset to the first page when searching
    };

    const handleKeyFeatureFilter = (e) => {
        setKeyFeatureFilter(e.target.value);
        setCurrentPage(0);  // Reset to the first page when filtering
    };

    const handleAppNameFilter = (e) => {
        setAppNameFilter(e.target.value);
        setCurrentPage(0);  // Reset to the first page when filtering
    };

    const handleLeversOfBenefitFilter = (e) => {
        setLeversOfBenefitFilter(e.target.value);
        setCurrentPage(0);  // Reset to the first page when filtering
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const filterData = () => {
        return data.filter((item) => {
            const matchesReportName = item["Report Name"].toLowerCase().includes(searchTerm.toLowerCase());
            const matchesKeyFeature = keyFeatureFilter ? item["Key Feature"] === keyFeatureFilter : true;
            const matchesAppName = appNameFilter ? item["App Name"] === appNameFilter : true;
            const matchesLeversOfBenefit = leversOfBenefitFilter ? item["Levers of Benefit"] === leversOfBenefitFilter : true;

            return matchesReportName && matchesKeyFeature && matchesAppName && matchesLeversOfBenefit;
        });
    };

    const filteredData = filterData();
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);
    const displayedData = filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    return (
        <div>
            <div style={{display:"flex", justifyContent:"space-between", alignContent:"center", alignItems:"center"}}>
                <span style={{fontWeight:"600", fontSize:"24px"}}>Report Store </span>
                <input 
                    type="text" 
                    placeholder="Search by Report Name" 
                    value={searchTerm} 
                    onChange={handleSearch} 
                    style={{
                        width:"400px",
                        paddingLeft:"10px",
                        borderRadius:"6px",
                        border:"2px solid #eee",
                        height:"45px"
                    }}
                />
            </div>
            <div className="filters">
                <select value={keyFeatureFilter} onChange={handleKeyFeatureFilter}>
                    <option value="">Filter by Key Feature</option>
                    {uniqueKeyFeatures.map((feature, index) => (
                        <option key={index} value={feature}>
                            {feature}
                        </option>
                    ))}
                </select>
                <select value={appNameFilter} onChange={handleAppNameFilter}>
                    <option value="">Filter by App Name</option>
                    {uniqueAppNames.map((app, index) => (
                        <option key={index} value={app}>
                            {app}
                        </option>
                    ))}
                </select>
                <select value={leversOfBenefitFilter} onChange={handleLeversOfBenefitFilter}>
                    <option value="">Filter by Levers of Benefit</option>
                    {uniqueLeversOfBenefit.map((lever, index) => (
                        <option key={index} value={lever}>
                            {lever}
                        </option>
                    ))}
                </select>
            </div>

            <div style={{display:"flex", justifyContent:"space-between", alignContent:"center", alignItems:"center"}}>
                <span>Total Number of Reports: {filteredData.length}</span>

                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    forcePage={currentPage}  // Adjust for 0-based indexing
                />
            </div>

            <table id='DataTable' border="1">
                <thead>
                    <tr>
                        <th>Report Name</th>
                        <th>Key Feature</th>
                        <th>Details</th>
                        <th>App Name</th>
                        <th>Key Benefits</th>
                        <th>Levers of Benefit</th>
                        <th>Expected Benefit</th>
                    </tr>
                </thead>
                <tbody>
                    {displayedData.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <a href='/' style={{textDecoration:"underline"}}>{item["Report Name"]}</a>
                            </td>
                            <td>{item["Key Feature"]}</td>
                            <td>{item.Details}</td>
                            <td>{item["App Name"]}</td>
                            <td>{item["Key Benefits"]}</td>
                            <td>{item["Levers of Benefit"]}</td>
                            <td>{item["Expected Benefit"]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Datatable;
