import React from 'react';
import * as XLSX from 'xlsx';

const DownloadJsonToExcel = ({data}) => {
  // Sample JSON data
  const jsonData = data;

  // Function to download JSON data as Excel file
  const downloadExcel = () => {
    // Convert JSON to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    // Generate the Excel file and trigger download
    XLSX.writeFile(workbook, "data.xlsx");
  };

  return (
    <div>
      <button onClick={downloadExcel}>Download Excel</button>
    </div>
  );
};

export default DownloadJsonToExcel;
