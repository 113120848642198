export const ReportData = [
    {
        name: 'C&S Weekly Inventory Report',
        content: [
            {
                content: 'Provides exhaustive inventory view of customer C&S Wholesale Grocers.',
            },
            {
                content: 'Helps track the consumption of customers in current period and next period visibility.',
            },
            {
                content: 'Total 5 tabs present: General filters - Brand, Production type, Demand PPG.',
                point: [
                    'Tab 1 - Item Summary: 2 bar charts (DOS wrt products & Historical avg OH vs DC shipments) & 1 tabular view (At item level DOS, OH & DC Shipments view against allocation details)',
                    'Tab 2 - Warehouse Summary: 2 bar charts (DOS wrt products & Historical avg OH vs DC shipments) & 1 tabular view (At WH level DOS, OH & DC Shipments view against allocation details)',
                    'Tab 3 - Inventory & Sales trend: 2 graphs (Historical plots of inventory & sales related KPIs & CSL trend)',
                    'Tab 4 - Demand review: 2 tables showcasing Item wise forecasted demand for next 13 periods',
                    'Tab 5 - Rebrand Summary: Unclear information',
                ],
            },
            {
                content: 'For existing elements',
                point: [
                    'Colour coding can be added to the table in Tab 1 & 2',
                    'Both Tabs 1 & 2 can be combined into one with addition of a filter',
                ],
            },
            {
                point: [
                    'Calculations - Medium complexity (multiple KPIs & variations being calculated)',
                    'Visualisations - Medium complexity (Graphical views difficult to understand)',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: ['N/A'],
            },
            {
                point: ['Loading time - under 5 seconds'],
            },
            {
                point: ['Accurate data, no issues'],
            },
        ],
    },
    {
        name: 'Inventory By DC report',
        content: [
            {
                content: 'Provide insights of inventory levels by SKUs across various distribution centres, considering projected demand levels',
            },
            {
                content: 'Used by - Supply Planning, CS&L, CMOS discussions. ',
            },
            {
                content: 'Total 6 tabs present: General Filters – Brand, Production Type, Production Line, PPG, Item Segmentation',
                point: [
                    'Tab 1 - DC Inventory After Orders: Visuals 3 tables + 1 Tornado chart ',
                    'Tab 2 - DC Inventory Before Orders: Visuals 3 tables + 1 Tornado chart ',
                    'Tab 3 - OH No Inbounds: Visuals 2 tables + 1 Tornado chart ',
                    'Tab 4 - DC DOS: Visuals 3 tables + 1 Tornado chart ',
                    'Tab 5 - DC Splits: Visuals 1 table ',
                    'Tab 6 - Pallet Capacity by DC: Visuals 1 tables + Speedometer '
                ],
            },
            {
                content: 'For existing elements',
                point: [
                    'Tabs 1, 2 & 5 can be combined as they have exact same layout and elements, by addition of 1 filter ',
                    'Tornado chart - not the best visual for projected production data. Histogram or line graph - alternate ',
                    'Filters currently selected in 1 tab impacting all tabs - This can be enabled at individual tab level '
                ],
            },
            {
                point: [
                    'Calculations - Low complexity (addition, subtraction, % calculations visible)',
                    'Visualisations - Medium complexity (tabular visuals can be optimised to graphical & simpler views) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: ['Report lacking forward looking views (projections) ', '$ value of inventory currently missing '],
            },
            {
                point: ['Loading time - under 5 seconds'],
            },
            {
                point: ['Accurate data, no issues'],
            },
        ],
    },
    {
        name: 'Bracket Scorecard',
        content: [
            {
                content: 'Shows customer (for retail) wise ratings, details related to ordering behaviour, compliance percentage and allocated brackets.',
            },
            {
                content: 'Used by customer service team to identify compliant & non-compliant customers and take necessary actions. ',
            },
            {
                content: 'Total 3 tabs present:  ',
                point: [
                    'Tab 1 - Retail Customer Scorecard: 2 tables (Customer details & KPIs related to order bracket, Avg order details and compliance with full layer & full pallet ordering) & 1 key indicated customer bracket allocation logic  ',
                    'Tab 2 - Customer not ordering in full layers: Tabular view of filtered out customers not ordering in full layers expanded to item/SKU level ',
                    'Tab 3 - Quarterly review process: Slide of steps to be taken for non-compliant customers at quarterly level ',
                ],
            },
            {
                content: 'For existing elements',
                point: [
                    'Tab 2 can be combined with Tab 1 by adding another predefined filtering button. ',
                ],
            },
            {
                point: [
                    'Calculations – Low complexity (simple aggregation & filtering of data) ',
                    'Visualisations - Low complexity (only tabular visuals) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: ['N/A'],
            },
            {
                point: ['Loading time - under 5 seconds'],
            },
            {
                point: ['Accurate data, no issues'],
            },
        ],
    },
    {
        name: 'Costco Weekly Inventory Report',
        content: [
            {
                content: 'Shows inventory & sales trend for retailer Costco',
            },
            {
                point: [''],
            },
            {
                point: [
                    'Total 2 tabs present:',
                    'Tab 1 – Inventory & Sales trend: 3 line graphs (out of stock % by date, weeks of supply by date, pallet on hand and dollar state by date), with filters of material & region',
                    'Tab 2 – Region Summary: Region wise breakdown of inventory in pallets and $ sales',
                ],
            },
            {
                point: [
                    'For new elements:',
                    'Possibility of adding other similar retail customers and perform comparative analysis.',
                ],
            },
            {
                point: [
                    'Calculations – Medium complexity (simple aggregation, Out of stock % calculations)',
                    'Visualisations - Low complexity (simple line graphs & tabular visuals)',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                content: 'Gaps shared by Business',
                point: ['N/A'],
            },
            {
                content: 'Performance – User experience stand-point',
                point: ['Loading time – under 5 seconds'],
            },
            {
                content: 'Data Quality/Accuracy observations',
                point: ['Accurate data, no issues'],
            },
        ],
    },
    {
        name: 'Customer Case Fill Dashboard 2.0',
        content: [
            {
                content: 'Provide view of Customer Service Levels at yearly, monthly, weekly level – summed up at product portfolio level and bifurcated at item level, customer, plant levels. Also shows a view of unfulfilled orders (cuts)',
            },
            {
                content: 'Utilised by all sub functions within CSL domain.',
            },
            {
                content: 'Total 10 tabs present: General Filters - Brand, Production Type, Production Line, PPG, Item Segmentation',
                point: [
                    'Total 10 tabs present: General Filters - Brand, Production Type, Production Line, PPG, Item Segmentation',
                    'Tab 1 – CSL Summary: Speedometer + 2 tables + Number cards',
                    'Tab 2 – CMOS Dashboard: Speedometer + 2 tables + Number cards',
                    'Tab 3 – CSL By DC: Speedometer + 2 tables + Number cards',
                    'Tab 4 – Item CSL by Period: Bar charts + Table , no filters',
                    'Tab 5 – Customer CSL by Period: Bar charts + Table , no filters',
                    'Tab 6 – Plant CSL by Period: Bar charts + Table , no filters',
                    'Tab 7 – Ordered vs Shipped GSV: Bar charts + 2 Table , no filters',
                    'Tab 8 – Ordered vs Shipped cases: Bar charts + 2 Table , no filters',
                    'Tab 9 – DC Splits: 2 tables. No filters',
                    'Tab 10 – Sales Order Details: 1 table, no filters',
                ],
            },
            {
                content: 'For existing elements ',
                point: [
                    'Tabs 1, 2 & 5 can be combined as they have exact same layout and elements, by addition of a new filter',
                    'Tabs 4, 5 & 6 can be combined as they have exact same layout and elements, by addition of a new filter',
                    'Tabs 7 & 8 can be combined as they have exact same layout and elements, by addition of a new filter',
                    'Addition of tab level filters in tabs 4-10',
                    'Colour coding can be added to Top Cuts table (Tab 1 &2)',
                    'CSL by period – Better representation would be line graph',
                    'CSL by period – Colour coding to the table',
                    'Utilisation of Sales order details tab?',
                    'New elements in the report (from external best practices research)',
                    'DC filter can be added',
                    'CSL projections – can be added',
                    'Historical trend of cuts – by DCs, by Customers, by Items',
                ],
            },
            {
                point: [
                    'Calculations – Medium complexity (multiple pivots, CSL calculations)',
                    'Visualisations - Low complexity (tabular visuals can be optimised to graphical & simpler views)',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A ',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
        ],
    },
    {
        name: 'Order Bank Day 20',
        content: [
            {
                content: 'Provides view of status of orders under various buckets in terms of $ value, and comparison against revenue targets and daily allocation ',
            },
            {
                content: 'N/A ',
            },
            {
                content: 'Total 3 tabs present: General filters – Brand, Production type, Demand PPG ',
                point: [
                    'Tab 1 – Daily Snapshots: Speedometer view of CSL & Tabular view of GSV under various buckets of shipped, appointed, ordered, cuts etc for various retail outlets. ',
                    'Tab 2 – Pacing: 2 tables (Order amounts categorised under Open, WH, Scheduled etc distributed on daily level & Accumulated GSV at Parent account level)  ',
                    'Tab 3 – Shifts: Aggregated view at Parent account level of Open WH & WH scheduled $ value ',
                ],
            },
            {
                content: 'For existing elements',
                point: [
                    'Can be consolidated with Order bank dashboard ',
                    'Columns can be moved to filters in Tabs 1 & 2 '
                ],
            },
            {
                point: [
                    'Calculations – Low complexity (simple aggregations & % calculations) ',
                    'Visualisations - Medium complexity (tables hard to understand & navigate) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: ['Report lacking forward looking views (projections) ', '$ value of inventory currently missing '],
            },
            {
                point: ['Loading time - under 5 seconds'],
            },
            {
                point: ['Accurate data, no issues'],
            },
        ],
    },
    {
        name: 'Foodservice Daily Item & Orders Update',
        content: [
            {
                content: 'Shows item wise inventory details of Greenville DC and details of current open orders at customer & item level. '
            },
            {
                content: 'N/A ',
            },
            {
                content: 'Single tab report: 2 tabular views (Greenville inventory & current open orders) & 1 tornado chart (planned production per week) ',
            },
            {
                content: 'For existing elements',
                point: [
                    'Addition of filters to select specific customers or items. ',
                    'Planned production, horizontal bar graph would be better representation. ',
                    'Incorporation of other production locations or DCs possible '
                ],
            },
            {
                point: [
                    'Calculations – Low complexity (simple aggregations) ',
                    'Visualisations - Low complexity (simple line graphs & tabular visuals) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: ['N/A '],
            },
            {
                point: ['Loading time - under 5 seconds'],
            },
            {
                point: ['Accurate data, no issues'],
            },
        ],
    },
    {
        name: 'Order Bank',
        content: [
            {
                content: 'Provides view of status of orders under various buckets in terms of $ value, and comparison against revenue targets and daily allocation.',
            },
            {
                content: 'N/A',
            },
            {
                content: 'Total 6 tabs present: General filters – Brand, Production type, Demand PPG',                
                point: [
                    'Tab 1 – Daily Snapshots: Speedometer view of CSL & Tabular view of GSV under various buckets of shipped, appointed, ordered, cuts etc for various retail outlets.',
                    'Tab 2 – Pacing: 2 tables (Order amounts categorised under Open, WH, Scheduled etc distributed on daily level & Accumulated GSV at Parent account level)',
                    'Tab 3 – Shifts: No data',
                    'Tab 4 – Past due open orders:  Aggregated number of late orders, past due GSV, & avg days late & Tabular view of Customer wise details of open order amount & delays',
                    'Tab 5 – Potential cuts: Potential cuts of open orders in terms of cases, customer & material wise',
                    'Tab 6 – Old Riceland Item tracker: Unclear',
                ],
            },
            {
                content: 'For existing elements:',
                point: [
                    'Tabs 3 & 6 can be removed',
                    'Columns can be moved to filters in Tabs 1, 2 & 5',
                ],
            },
            {
                point: [
                    'Calculations – Low complexity (simple aggregations & % calculations)',
                    'Visualisations - Medium complexity (tables hard to understand & navigate)',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name: 'Remaining Allocation & Quota by DC',
        content: [
            {
                content: 'Provides view of allocation & quota amount for all customers distributed across various DCs. '
            },
            {
                content: 'Helps track the consumption of customers in current period and next period visibility ',
            },
            {
                content: 'Total 2 tabs present: General filters – Brand, Production type, Demand PPG ',                
                point: [
                    'Tab 1 –Current Period: Tabular view of item/SKU, customer with columns as – DC names, allocated and consumed quantity. ',
                    'Tab 2 – Next Period:  Tabular view of item/SKU, customer with columns as – DC names, allocated and consumed quantity. '
                ],
            },
            {
                content: 'For existing elements:',
                point: [
                    'Aggregated numbers can also be shown for each customer (reverse hierarchy in tables) ',
                    'Both views can be combined into a single tab ',
                ],
            },
            {
                point: [
                    'Calculations – Low complexity (simple aggregations & % calculations)',
                    'Visualisations - Low complexity (simple line graphs & tabular visuals)',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name: 'Daily Allocation & Quota Report',
        content: [
            {
                content: 'The objective of this report is to  ',
                point: [
                    'View remaining stock vs consumed stock for current and next period on item and customer level',
                    'view allocated vs invoiced/shipped consumed for current and next period on item and customer level the above are done at the item level and customer level '
                ]
            },
            {
                content: 'N/A',
            },
            {
                content: 'This report contains following pages',                
                point: [
                    'customer review current period – 4 slicers (Brand, product category, Demand PPG, item status) + 6 KPIs (Allocation/quota, consumed, remaining, % consumed, invoiced/shipped, % invoiced/shipped) + 1 table',
                    'customer review next period – 4 slicers (Brand, product category, Demand PPG, item status) + 6 KPIs (Allocation/quota, consumed, remaining, % consumed, invoiced/shipped, % invoiced/shipped) + 1 table ',
                    'item review current period - 4 slicers (Brand, product category, Demand PPG, item status) + 6 KPIs (Allocation/quota, consumed, remaining, % consumed, invoiced/shipped, % invoiced/shipped) + 1 table ',
                    'item review next period - 4 slicers (Brand, product category, Demand PPG, item status) + 6 KPIs (Allocation/quota, consumed, remaining, % consumed, invoiced/shipped, % invoiced/shipped) + 1 table  '
                ],
            },
            {
                content: 'For existing elements:',
                point: [
                    'Aggregated numbers can also be shown for each customer (reverse hierarchy in tables) ',
                    'Both views can be combined into a single tab ',
                ],
            },
            {
                point: [
                    'Calculations – Low complexity (addition, % calculations for KPIs) ',
                    'Visualisations - Low complexity (bar charts & graphs can be added) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name: 'Mars Food New Orders Report',
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name: 'IPM US Food Daily view',
        content: [
            {
                content: 'Provide inventory levels by segment, category, location, brand, sub brand, zrep & item  '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'overview – 19 slicers (country, market grouping, market mapping1, market mapping2, market mapping3, segment, currency, category, location, item, representative item, brand flag, sub-brand flag, packaging format, packaging type, product type, factory name, production line, UOM) + 2 doughnut charts (inventory by category-Apollo, Atlas + 2 tables (inventory in volume by segment,  inventory by location, brand, sub-brand, zrep & item) + 1 map (inventory by postal code) ',
                    'summary – 11 slicers (business segment, category, location, item, representative item, brand flag, sub brand flag, packaging format, packaging type, currency, uom) + 1 table '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity  ',
                    'Visualisations - Low complexity ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name: 'IPM US Food weekly view',
        content: [
            {
                content: 'Provide insights about Inventory for different functions such as capacity planner, finance planner & supply planner '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'capacity controller – 15 slicers (market, location (destination), segment, Mars Year, Mars period, Mars week, production line, category, packing format, packing type, brand flag, sub brand flag, representative item, item, factory) + 3 doughnut charts (risk instances by segment, packaging format, category) + 1 table + 2 column charts (total inventory vs maximum capacity, projected inventory & in transit stock information, availability and in transit stock across weeks)  ',
                    'finance controller – 2 (mars period, market, segment, mars period, category, packaging type, sub brand flag, production line, item, location, mars year, mars week, packaging format, brand flag, factory, representative item, currency) + 1 doughnut chart (top 10 brand value) + 1 line chart (replan vs projected value) + 2 tables + 2 bar charts (total projected inventory vs current actuals vs LY actuals, total projected inventory vs current actuals vs replan) ',
                    'supply planner - 11 slicers  (mars year, mars period, mars week, market, business segment, category, production line, site, planner group, pre calculated selection level, in transit selection) + 2 tables (heat map based on cover vs target, JDA vs target cover) + 2 column charts (split of cover days across markets, planned vs scheduled vs actual production – this has 13 slicers as well) + line & column chart (JDA vs prophets vs actuals)  + 1 bar chart (dormant stock in current week, has 13 slicers as well)  + line & column chart (JDA vs prophets vs actuals)  '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Medium complexity (multiple % calculations for KPIs) ',
                    'Visualisations - Medium complexity (value on x axis difficult to understand)  ',
                    'Filters & Navigation – many filters are there , although easy to navigate ',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    /////
    {
        name: 'SPM V2 PROD (Use This)',
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    ///
    {
        name: 'Mars Food Daily Business Position',
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name: 'Inventory Quality Dashboard',
        content: [
            {
                content: 'Provides a view of Inventory in different locations and under various buckets of quality & aging.'
            },
            {
                content: 'Used by supply planning team.',
            },
            {
                content: 'Total 4 tabs present: General Filters – Stock type, Quality Inspection, Aging Status, Storage location, Plant, Item, Batch code',                
                point: [
                    'Tab 1 – Inventory Summary: 3 breakdown bar charts (stock value by DC, product category, aging status) + 1 table (plant wise stock value, #cases & #pallets)',
                    'Tab 2 – Quality Summary: 1 bar chart (quality holds cost) + 2 tables (quality holds cost by DC)',
                    'Tab 3 – Aging & Excess Inventory: 2 tables, 1 bar chart ,Projected inventory by period under various shelf life buckets in terms of cases & cost',
                    'Tab 4 – Hot Sheet: Tabular view of SKU & DC wise cases in various shelf-life buckets'
                ],
            },
            {
                content: 'For existing elements:',
                point: ['Space Optimisation possible in tabs 1, 2 & 4', 'Colour coding can be added in tab 3', 'Projected write off amount – horizontal graph would be better representation.',
                        'Filters can be added as moved to the tabs and made more visible'],
            },
            {
                point: [
                    'Calculations – Low complexity (simple pivots & summations)',
                    'Visualisations - Low complexity',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                'Absence of inventory projections, forward looking view in terms of cases, pallets & GSV',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    ///
    {
        name: 'SPM V2 CANADA',
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    ////
    {
        name: 'Logistics Efficiency',
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    ///
    {
        name:  `Ben's Original Risks - NA`,
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },

    //New
    {
        name:  `Food SLOMO`,
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name:  `Projected Cut Dashboard	`,
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name:  `Global Food Planning Control Tower (PROD)`,
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name:  `PPG Rollup (US)`,
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name:  `GSV File (CA)`,
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name:  `Ship vs. consumption`,
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name:  `Cycle over cycle reporting (inclusive of VF)`,
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name:  `Global Food Demand Consensus (PROD)`,
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    },
    {
        name:  `SFA and BIAS Dashboard 2.0`,
        content: [
            {
                content: 'Provides view of new orders received by Mars by day (at current and historical level) '
            },
            {
                content: 'N/A',
            },
            {
                content: 'It has the following pages',                
                point: [
                    'New orders - 1 column chart (new orders by sold to) +1 bar chart (GSV by DC) + 2 tables (data dump, GSV by GI period) ',
                    'Order GSV by day (Current Period) – 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (Order GSV by current period & future period) + 2 slicers (channel & sales director) ',
                    'Order GSC by day (Historical) - 3 KPIs (Total GSV, Current period GSV, Future period GSV) + 1 Stacked column chart (historical Order GSV by current period & future period) + 3 slicers (channel , sales director & order creation period) '
                ],
            },
            {
                content: 'For existing elements:',
                point: ['N/A'],
            },
            {
                point: [
                    'Calculations – Low complexity (multiple KPIs being calculated) ',
                    'Visualisations - Low complexity (Graphical views easy to understand) ',
                    'Filters & Navigation - Easy',
                ],
            },
            {
                point: [
                    'N/A',
                ],
            },
            {
                point: [
                    'Loading time – under 5 seconds',
                ],
            },
            {
                point: [
                    'Accurate data, no issues',
                ],
            },
        ],
    }
];
