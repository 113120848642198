import React, { useState } from "react";
import UserInfo from "./components/UserInfo";
import MainLayout from "../../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import PrintIcon from "@mui/icons-material/Print";
import classes from "./userHome.module.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// Function to generate a random role ('Admin' or 'Member')
const getRandomRole = () => {
  return Math.random() < 0.5 ? "Admin" : "Member";
};
const getRandomAdmin = () => {
  return Math.random() < 0.5 ? "admin1@example.com" : "admin2@example.com";
};

// Function to generate a random user group
const getRandomUserGroup = () => {
  const userGroups = ["Group A", "Group B", "Group C", "Group D"];
  const numGroups = Math.floor(Math.random() * userGroups.length) + 1; // Random number of groups between 1 and userGroups.length
  const selectedGroups = [];

  for (let i = 0; i < numGroups; i++) {
    const groupIndex = Math.floor(Math.random() * userGroups.length);
    const selectedGroup = userGroups[groupIndex];
    if (!selectedGroups.includes(selectedGroup)) {
      selectedGroups.push(selectedGroup);
    }
  }

  return selectedGroups;
};

// Array to store dummy user data
const users = [];

// Function to generate unique ID
const generateUniqueID = () => {
  return Math.floor(Math.random() * 1000) + 1; // Random number between 1 and 1000
};

const getFirstName = () => {
  var firstNameList = [
    "John",
    "Emma",
    "Michael",
    "Sophia",
    "James",
    "Olivia",
    "Robert",
    "Ava",
    "William",
    "Isabella",
    "David",
    "Mia",
    "Joseph",
    "Emily",
    "Daniel",
    "Abigail",
    "Benjamin",
    "Charlotte",
    "Samuel",
    "Amelia",
  ];
  var randomFirstName =
    firstNameList[Math.floor(Math.random() * firstNameList.length)];
  return randomFirstName;
};

const getLastName = () => {
  var lastNameList = [
    "Smith",
    "Johnson",
    "Williams",
    "Jones",
    "Brown",
    "Davis",
    "Miller",
    "Wilson",
    "Moore",
    "Taylor",
    "Anderson",
    "Thomas",
    "Jackson",
    "White",
    "Harris",
    "Martin",
    "Thompson",
    "Garcia",
    "Martinez",
    "Robinson",
  ];
  var randomLastName =
    lastNameList[Math.floor(Math.random() * lastNameList.length)];
  return randomLastName;
};

// Function to generate a random password
function generatePassword() {
  var specialChars = "!@#$%^&*()_-+=<>?/[]{}|";
  var uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
  var numbers = "0123456789";

  var allChars = specialChars + uppercaseLetters + lowercaseLetters + numbers;
  var password = "";

  // Random password length between 8 and 20 characters
  var passwordLength = Math.floor(Math.random() * (20 - 8 + 1)) + 8;

  // Ensure at least one special character, one uppercase letter, and one number
  var hasSpecialChar = false;
  var hasUppercaseLetter = false;
  var hasNumber = false;

  // Add at least one of each required character type
  password += specialChars[Math.floor(Math.random() * specialChars.length)];
  password +=
    uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];

  for (var i = 3; i < passwordLength; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // Shuffle the password characters to ensure randomness
  password = password
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");

  return password;
}

// Generate 15 users
for (let i = 0; i < 15; i++) {
  const user = {
    firstName: getFirstName(),
    lastName: getLastName(),
    ID: generateUniqueID(),
    Email: `user${i + 1}@example.com`,
    AssignedBy: getRandomAdmin(), // Assuming admin assigns users
    Role: getRandomRole(),
    password: generatePassword(),
  };
  users.push(user);
}

const UserHome = () => {
  const [userData, setUserData] = useState(users);
  const [printData, setPrintData] = useState(false);

  const userDataHandler = (userData) => {
    // const userGroupArr = userData.userGroups.map((item) => item.value);

    if (userData.ID) {
      setUserData((prevState) => {
        return prevState.map((ele) => {
          if (ele.ID === userData.ID) {
            return {
              firstName: ele.firstName,
              lastName: ele.lastName,
              ID: userData.ID,
              Email: userData.email,
              AssignedBy: getRandomAdmin(), // Assuming admin assigns users
              Role: userData.role,
              password: ele.password,

              // UserGroup: [...userGroupArr], // Assigning random user groups
            };
          } else {
            return ele;
          }
        });
      });
    } else {
      setUserData((prevState) => {
        return [
          {
            firstName: userData.firstName,
            lastName: userData.lastName,
            ID: generateUniqueID(),
            Email: userData.email,
            AssignedBy: getRandomAdmin(), // Assuming admin assigns users
            Role: userData.role,
            password: userData.password,
            // UserGroup: [...userGroupArr], // Assigning random user groups
          },
          ...prevState,
        ];
      });
    }
  };

  const deleteHandler = (userId) => {
    setUserData((prevState) => {
      return prevState.filter((ele) => ele.ID !== userId);
    });
  };
  return (
    <div className={classes["main-container"]}>
      <Box
        className="mainLayout"
        sx={{ display: "flex", overflow: "hidden", overflowY: "auto" }}
      >
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id={classes["user-management"]}>
            <div className={classes.navbar}>
              <ul className={classes["menu-list"]}>
                <li className={`${classes["menu-item"]}`}>User Management</li>
                <div className={classes.line}></div>
              </ul>

              <div className={classes["nav-btn"]}>
                <button
                  style={{background:"#3d3d3d"}}
                  className={`${classes["primary-btn"]} ${classes["print-btn"]}`}
                  onClick={() => setPrintData(true)}
                >
                  <PrintIcon />
                </button>
                <button
                                  style={{background:"#3d3d3d"}}

                  className={classes["primary-btn"]}
                  onClick={() => window.location.reload()}
                >
                  Refresh
                </button>
              </div>
            </div>

            <UserInfo
              users={userData}
              onAddData={userDataHandler}
              onDeleteData={deleteHandler}
              printData={printData}
              setPrintData={setPrintData}
            />
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default UserHome;
