import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../assets/img/Aays.png";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${300}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${84}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Topnavbar = (props) => {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openDialog, setopenDialog] = useState(false);
  // const [openDialogq1, setopenDialog1] = useState(false);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          style={{ backgroundColor: "white", boxShadow: "none",zIndex:"1" }}
          open={props.open}
        >
          <Toolbar
            className="navBarStyle"

            style={{
              justifyContent: "space-between",
              paddingTop: "20px",
              alignItems: "center",
              paddingBottom: "20px",
              height:"82px"
            }}
          >
            <Typography
              variant="h6"
              style={{ color: "black", fontSize: "28px", fontWeight: "700",width:"100%",textAlign:"left" }}
              noWrap
              component="div"
              className="navBarStyleColor"
            >
              <img src={logo} alt="logo" width={130}/>
            </Typography>

            <div
              style={{
                width: "100%",
                justifyContent: "flex-end",
                gridGap: "2rem",
                display: "flex",
              }}
            >
            

             
            </div>

     
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Topnavbar;
