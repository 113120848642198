import React, { useState, useRef } from "react";
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ReactPaginate from "react-paginate";
import { Button } from "react-bootstrap";
import $ from "jquery";
import JsonData from '../../datacatalog.json'
import ReportGenerate from "../reuseable/ReportGenerate";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const ReportSummary = () => {
  
  const [error, setError] = useState(false);
  const [iserror, setisError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPagelimit, setPageLimit] = useState(20);
  const [filterFunctionBusiness, setfilterFunctionBusiness] = useState("");
  const [filterByCalculated, setfilterByCalculated] = useState("");
  const [filterFunction, setFilterFunction] = useState("");
  const [query, setQuery] = useState("");

  const componentRef_1 = useRef(null);

  const items = JsonData
  const sortedAsc = (items === null ? [] : items.slice(0).sort((a, b) => {
    // Check if 'S. No' property exists in a and b
    if (a && b && a['S. No'] && b['S. No']) {
        return b['S. No'].localeCompare(a['S. No']);
    } else {
        // Handle case where 'S. No' property is missing
        return 0; // Or return a value suitable for your sorting logic
    }
  }));
    
   const data = sortedAsc === null ? " " : Object.values(sortedAsc);
   
    const search_parameters = ["Reportname",'ReportType','ReportOwnerBusiness','Productowner','FunctionDetails','Businesssubfunctionlevel1','ReportObjective','Keydecisionssupported'];
    const Filter_Function_Business_lens1 = [...new Set(data.map((item) => item.ReportOwnerBusiness))];

  const Filter_Calculated_Yes_No1 = [...new Set(data.map((item) => item.ReportType))];
  const Filter_Function1 = [...new Set(data.map(item => item.FunctionDetails))];

    const Filter_Calculated_Yes_No = Filter_Calculated_Yes_No1.filter(item => item !== '');
  const Filter_Function_Business_lens = Filter_Function_Business_lens1.filter(item => item !== '');
  const Filter_Function = Filter_Function1.filter(item => item !== '');
    

  
  
  
    console.log(Filter_Calculated_Yes_No)

    let filteredData = [];

    if(items !== null){
        filteredData = sortedAsc.filter((obj) => {
            return obj;
          });
    }

 

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
      };
     
      
    function search(sortedAsc) {
        return sortedAsc.filter(
          (item) =>
            item.ReportType.includes(filterByCalculated) &&
            item.ReportOwnerBusiness.includes(filterFunctionBusiness) && item.FunctionDetails.includes(filterFunction)&&

            search_parameters.some(
              (parameter) =>
                item[parameter].toString().toLowerCase().includes(query.toLowerCase()) 
            )
           
        );
    }

      let NUM_OF_RECORDS = search(filteredData).length;
      if (NUM_OF_RECORDS <= currentPagelimit) {
        $(".pagination").hide(true);
      } else {
        $(".pagination").show(true);
      }

      const currentData = search(filteredData).slice(
        (currentPage - 1) * currentPagelimit,
        (currentPage - 1) * currentPagelimit + currentPagelimit
      );
    
      const pageCount = Math.ceil(search(filteredData).length / currentPagelimit);

      const handelepageclick = (data) => {
        console.log(data.selected);
        setCurrentPage(data.selected + 1);
      };

     console.log("currentData")
      console.log(JsonData)
      const resetAll = () =>{
        handlePageChange({ selected: 1 });
        setfilterFunctionBusiness("")
        setQuery("")
        setfilterByCalculated("")
      }

  console.log(filteredData);


  const showBusinessLens = (ele) => {
    const newEle = ele.split(',');
    console.log(newEle);
    return (
      newEle.slice(0,1).map((item) => (
        <span
        style={{
          display: "grid",
          justifyContent: "center",
          cursor:"pointer",
          marginBottom: '8px',
        }}
      >
        {item === "CS&L" ? (
          <div className="item_CSL itemsFlex">
            <span title={item}>{item}</span>
          </div>
        ) : item === "Supply Planning" ? (
          <div className="supplyPlaning itemsFlex">
            <span title={item}>{item}</span>
          </div>
        ) : (item === "Supply Planning, CS&L" || item === "Supply Planning, CS&L") ? (
          <div className="SupplyPlanningCSL itemsFlex">
            <span title={item}>{item}</span>
          </div>
        ) : item === "Demand Planning" ? (
          <div className="DemandPlanning itemsFlex">
            <span title={item}>{item}</span>
          </div>
        ): item === "Demand Planning, Supply Planning" ? (
          <div className="DemandPlanningSupplyPlanning itemsFlex">
            <span title={item}>{item}</span>
          </div>
        )
        : item === "-" ? (
          <div>
            <span title={`No Data Available because of state is ${item}`}>{item}</span>
          </div>
        ) : (
          <div className="naitems itemsFlex">
            <span title={item}>
              {item === "" ||
              item === "NA"
                ? "N/A"
                : item}
            </span>
          </div>
        )}
        </span>
      ))

    )
  }

  // const showBusinessLens = (ele) => {
  //   const newEle = ele.split(',');
  //   console.log(newEle);
  //   return (
  //     <span className="tableTd">
  //       <div>
  //       {newEle.slice(0, 1).map((item, index) => (
  //         <span
  //           key={index}
  //           style={{
  //             display: "grid",
  //             justifyContent: "center",
  //             cursor: "pointer",
  //             marginBottom: "8px",
  //           }}
  //         >
  //           {item === "CS&L" ? (
  //             <div className="item_CSL itemsFlex">
  //               <span title={item}>{item}</span>
  //             </div>
  //           ) : item === "Supply Planning" ? (
  //             <div className="supplyPlaning itemsFlex">
  //               <span title={item}>{item}</span>
  //             </div>
  //           ) : (item === "Supply Planning, CS&L" || item === "Supply Planning, CS&L") ? (
  //             <div className="SupplyPlanningCSL itemsFlex">
  //               <span title={item}>{item}</span>
  //             </div>
  //           ) : item === "Demand Planning" ? (
  //             <div className="DemandPlanning itemsFlex">
  //               <span title={item}>{item}</span>
  //             </div>
  //           ) : item === "Demand Planning, Supply Planning" ? (
  //             <div className="DemandPlanningSupplyPlanning itemsFlex">
  //               <span title={item}>{item}</span>
  //             </div>
  //           ) : item === "-" ? (
  //             <div>
  //               <span title={`No Data Available because of state is ${item}`}>{item}</span>
  //             </div>
  //           ) : (
  //             <div className="naitems itemsFlex">
  //               <span title={item}>
  //                 {item === "" || item === "NA" ? "N/A" : item}
  //               </span>
  //             </div>
  //           )}
  //         </span>
  //       ))}
  //       </div>
    
  //   <div>
  //   {/* {newEle.length > 1 && <span>...</span>} */}

  //   </div>
  //     </span>
  //   );
  // };
  
  

  const transformData = (excelData) => { 
    console.log(excelData, 'excelData');  
    const newArray = excelData.map(obj => {        
        const { Reportname, ReportType, ReportOwnerBusiness, Productowner, FunctionDetails, Businesssubfunctionlevel1, ReportObjective, Keydecisionssupported } = obj;
        return { "Report Name":Reportname, "Report Type": ReportType, "Report Owner": ReportOwnerBusiness, 
        "Product Owner":Productowner , "Function":FunctionDetails, "Sub Function": Businesssubfunctionlevel1, "Report Objective": ReportObjective, "Key Supported": Keydecisionssupported};
    });
    return newArray;
  };


  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden",overflowY:"auto" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div style={{padding:"15px"}} id="mainHome">

        { filteredData.length !== 0 && !error && (
          <>
            <div className="wrapper">
              <div
                style={{ paddingBottom: "1rem", gridTemplateColumns: "100%" }}
                className="searchBarSections"
              >
                <div
                  style={{
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                    display: "flex",
                    gridTemplateColumns: "100%",
                    justifyContent: "space-between",
                    alignItems: 'center'
                  }}
                  className="searchBarSections"
                >
                  <div>
                    <div className="heading1">
                    KPI Store
                    </div>
                    <div className='line1' />
                  </div>


                  <div>
                    <ReportGenerate
                      excelData={filteredData}
                      fileName={`report_summary${new Date().getTime()}`}
                      transformData={transformData}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0rem",
                  flexWrap: "wrap",
                  gridGap: "10px",
                }}
              >
                <div className="search-wrapper">
                  <input
                    type="search"
                    name="search-form"
                    id="search-form"
                    value={query}
                    className="SearchBox_newworkflow"
                    placeholder="Search here"
                    autoComplete="off"
                    onChange={(e) => {
                      setQuery(e.target.value);
                      handlePageChange({ selected: 1 });
                    }}
                  />
                </div>

                <div style={{ display: "flex", gridGap: "20px" }}>
                
                      <div className="selectParent select">
                    <select
                      value={filterFunction}
                      onChange={(e) => {
                        setFilterFunction(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select custom-selectworkflow"
                      aria-label="Filter Calculated"
                    >
                      <option value="">Function</option>
                      {Filter_Function.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                  <div className="selectParent select">
                    <select
                      value={filterByCalculated}
                      onChange={(e) => {
                        setfilterByCalculated(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select custom-selectworkflow"
                      aria-label="Filter Calculated"
                    >
                      <option value="">Report Type</option>
                      {Filter_Calculated_Yes_No.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                

                  <div className="selectParent select">
                    <select
                      value={filterFunctionBusiness}
                      onChange={(e) => {
                        setfilterFunctionBusiness(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select custom-selectworkflow"
                      aria-label="Filter status"
                    >
                      <option value="">Report Owner</option>
                      {Filter_Function_Business_lens.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                  <Button
                    style={{
                      background: "#253878",
                      border: "none",
                      outline: "none",
                      cursor: 'pointer'
                    }}
                    onClick={resetAll}
                  >
                    Reset
                  </Button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                    display:"flex",
                    alignItems:"center"
                  }}
                >
                  Total number of Kpis{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#253978",
                      paddingLeft:"10px"
                    }}
                  >
                    565
                  </span>
                 

                </div>

                <div
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <div>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handelepageclick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={currentPage - 1}
                    />
                  </div>
                </div>
              </div>

                <table
                  id="DataTable"
                  ref={componentRef_1}
                  style={{ width: "100%" }}
                  className="workflowTable"
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          background: "#d0d8e2",
                          color: "#253978",
                          textAlign: "left",
                          width:"20%"
                        }}
                      >
                        Report Name
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "10%",
                          
                        }}
                      >
                        Report Type
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width:"10%"

                        }}
                      >
                       Report Owner
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width:"12%"
                        }}
                      >
                       Product Owner
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "10%",
                        }}
                      >
                        Function
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          width: "10%",

                        }}
                      >
                        Sub Function
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                       Report Objective
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                       Key supported
                      </th>

                    

                    </tr>

                    {search(filteredData).length !== 0 && (
                      <>
                        {currentData.map((item, index) => (
                          <tr key={index}>
                            <td
                              className="data_directory"
                              style={{
                                justifyContent: "center",
                                textAlign: "left",
                                cursor:"pointer"
                              }}
                              title={item.Reportname}
                            >
                              <a target="_blank" without rel="noreferrer" href={item.ToolLink}>
                              {item.Reportname}
                              </a>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  title= {item.ReportType} 
                                  className={(item.ReportType === 'Excel' || item.ReportType === 'excel') ? 'excelCls' : (item.ReportType === 'Web site' || item.ReportType === 'web site') ?'websitecls' : (item.ReportType === 'Tableau' || item.ReportType === 'Tableau') ?'Tableaucls' : 'powerbicls'}
                                >
                                      {item.ReportType}                        
                                </span>
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  textAlign:"center",

                                  cursor:"pointer"
                                }}
                              >
                                
                                  <div className="data_directory_ReportOwnerBusiness itemsFlex">
                                    <span title={item.ReportOwnerBusiness}>
                                      {item.ReportOwnerBusiness === "" ||
                                      item.ReportOwnerBusiness === "N/A"
                                        ? "N/A"
                                        : item.ReportOwnerBusiness}
                                    </span>
                                  </div>
                              
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  textAlign:"center",

                                  cursor:"pointer"
                                }}
                              >
                                
                                  <div className="data_directory_ReportOwnerBusiness data_directory__loc itemsFlex">
                                    <span title={item.Productowner}>
                                      {item.Productowner === "" ||
                                      item.Productowner === "N/A"
                                        ? "N/A"
                                        : item.Productowner}
                                    </span>
                                  </div>
                              
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  textAlign:"center",

                                  cursor:"pointer"
                                }}
                              >      
                                <div className="data_directory_items_critical data_directory__loc itemsFlex">
                                  <span title={item.FunctionDetails}>
                                    {item.FunctionDetails === "" ||
                                    item.FunctionDetails === "N/A"
                                      ? "N/A"
                                      : showBusinessLens(item.FunctionDetails)}
                                  </span>
                                </div>
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  textAlign:"center",

                                  cursor:"pointer"
                                }}
                              >
                                <div className="data_directory_items_critical data_directory__loc itemsFlex">
                                  <span title={item.Businesssubfunctionlevel1}>
                                    {item.Businesssubfunctionlevel1 === "" ||
                                    item.Businesssubfunctionlevel1 === "N/A"
                                      ? "N/A"
                                      : item.Businesssubfunctionlevel1}
                                  </span>
                                </div>
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  textAlign:"center",

                                  cursor:"pointer"
                                }}
                              >
                                
                                  <div className="data_directory_items_critical data_directory__loc itemsFlex">
                                    <span title={item.ReportObjective}>
                                      {item.ReportObjective === "" ||
                                      item.ReportObjective === "N/A"
                                        ? "N/A"
                                        : item.ReportObjective}
                                    </span>
                                  </div>
                              
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  textAlign:"center",

                                  cursor:"pointer"
                                }}
                              >
                                
                                  <div className="data_directory_items_critical data_directory__loc itemsFlex">
                                    <span title={item.Keydecisionssupported}>
                                      {item.Keydecisionssupported === "" ||
                                      item.Keydecisionssupported === "N/A"
                                        ? "N/A"
                                        : item.Keydecisionssupported}
                                    </span>
                                  </div>
                              
                              </span>
                            </td>

                           

                          </tr>
                        ))}
                      </>
                    )} 

                     {search(filteredData).length === 0 && (
                      <tr style={{ textAlign: "center" }}>
                        <td
                          style={{ textAlign: "center", color: "red" }}
                          rowSpan={4}
                          colSpan={9}
                        >
                          No Data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              
            </div>
          </>
        )}

        {error && (
          <div className="PlaseWait">
            <div className="spinner_class">
              {`There is a problem fetching the post data ${iserror}`}
            </div>
            <div>Please try after sometime</div>
          </div>
        )}
           
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ReportSummary;
