import React from "react";
import classes from "./UserInfo.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const UserTable = React.forwardRef(
  (
    {
      filteredArray,
      editHandler,
      setDeleteModal,
      setUserDeleteID,
      setClearData,
      setOpenModal,
    },
    ref
  ) => {
    return (
      <div className={classes["table-container"]} ref={ref}>
        <table className={classes["main-table"]}>
          <thead>
            <tr>
              <th style={{ borderRadius: "6px 0px 0px 0px", width: "20%" }}>
                Name
              </th>
              <th style={{ width: "27.5%" }}>Email</th>
              <th style={{ width: "15%" }}>Role</th>
              <th style={{ width: "27.5%" }}>Assigned By</th>
              <th
                className={classes["action-header"]}
                style={{ borderRadius: "0px 6px 0px 0px", width: "10%" }}
              >
                Actions
              </th>
            </tr>
          </thead>
          {filteredArray !== undefined && filteredArray.length > 0 && (
            <tbody>
              {filteredArray.map((user) => (
                <tr key={user.ID}>
                  <td style={{ textTransform: "capitalize" }}>
                    {user.firstName + " " + user.lastName}
                  </td>
                  <td>{user.Email}</td>

                  <td className={`${classes["role-column"]}`}>
                    <div
                      className={`${
                        user.Role === "Member" ? classes["member-color"] : ""
                      }`}
                    >
                      {user.Role}
                    </div>
                  </td>
                  <td>{user.AssignedBy}</td>

                  <td className={classes["action-cont"]}>
                    <div className={classes["action-icons"]}>
                      <EditIcon
                        onClick={() => {
                          setClearData(false);
                          editHandler(user.ID);
                          setOpenModal(true);
                        }}
                        className={classes["edit-icon"]}
                      />
                      <DeleteIcon
                        className={classes["delete-icon"]}
                        onClick={() => {
                          setDeleteModal(true);
                          setUserDeleteID(user.ID);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {filteredArray.length === 0 && (
          <div className={classes["no-data"]}>
            <p>Sorry, no matching records found...</p>
          </div>
        )}
      </div>
    );
  }
);

export default UserTable;
