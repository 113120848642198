import React, { useState, useRef } from "react";
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import Datatable from "../layout/Report-store/Datatable";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const ReportStore = () => {
  
 

  
  
  

  


  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden",overflowY:"auto" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div style={{padding:"15px"}} id="mainHome">

                    <Datatable/>
           
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ReportStore;
